import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import type { Armalytix } from "@claimsgate/core-types";

export async function gatewayHelper<T extends Armalytix.FunctionsName>(
  functionName: T,
  data: Armalytix.FunctionsRequests[T]
): Promise<Armalytix.FunctionsResponses[T]> {
  console.log("gatewayHelper calling: ", functionName, "with data: ", data);
  const result = await onCallGateway({
    functionName: functionName as any,
    data,
  });
  console.log("gatewayHelper result", result);
  if (result.data?.error || !result.data?.data) {
    throw new Error(
      `OnCallGateway error - function: ${functionName}; error: ${JSON.stringify(result.data?.error)} ${JSON.stringify(
        result
      )}
      )}`
    );
  }

  return result.data.data;
}
