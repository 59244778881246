import type { ViewSubmitHelper, ViewSubmitEvent } from "./CheckboardCreditCheck";

export const viewSubmit: ViewSubmitHelper = (state, view, responseData?, selectedAddress?) => {
  const payload: ViewSubmitEvent = { view };
  if (responseData) {
    payload.responseData = responseData;
  }
  if (selectedAddress) {
    payload.selectedAddress = selectedAddress;
  }
  state.$emit("viewSubmit", payload);
};
