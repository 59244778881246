import { Checkboard } from "@claimsgate/core-types";
import { gatewayHelper } from "./gatewayHelper";

export async function createNonCraClaim(data: Checkboard.Gateway.CreateNonCraClaim.Request) {
  console.log("createNonCraClaim", data);

  const result = await gatewayHelper("createNonCraClaim", data);

  if (typeof result === "boolean") {
    return result;
  }

  return result.check?.nonCraLenders ?? [];
}
