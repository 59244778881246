import Vue from "vue";
import type { CheckboardAddress, ClientView, Questionnaire, Account, Check } from "./helpers/CheckboardCreditCheck";
import type { User, ValifiAddress } from "@claimsgate/core-types";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import type { ValifiQuestionnaire } from "@claimsgate/core-types";

export function getData(state: Vue) {
  return {
    db: getFirebaseBackend().firestore(),
    currentView: "initiateCheck" as ClientView,
    claimId: state.$route.params.claimId,
    funnelId: state.$route.params.funnelId,
    pageId: state.$route.params.pageId,
    userId: "",
    workspaceId: "",
    checkId: "",
    check: null as Check | null,
    confirmedLenders: null as Array<Account> | null,
    confirmedClaimLender: null as Account | null,
    user: null as User | null,
    selectedAddress: null as CheckboardAddress | ValifiAddress | null,
    currentAddress: null as CheckboardAddress | null,
    addresses: [] as CheckboardAddress[] | ValifiAddress[],
    questionnaire: null as Questionnaire | null,
    possibleLenders: null as Array<Account> | null,
    funnelService: new FunnelsService(getFirebaseBackend().firestore()) as FunnelsService | null,
    claimDataService: null as ClaimDataService | null,
    valifiQuestionnaire: null as ValifiQuestionnaire | null,
    failReason: null as string | null,
    referUrl: null as string | null,
    claimsToCreateEventsFor: [] as Array<string>,
  };
}
