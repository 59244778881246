import { ValifiAddress } from "@claimsgate/core-types";

/**
 * @description
 * Takes a ValifiAddress object and returns a nicely formatted address string for display.
 * This excludes the addressID which is not relevant for display.
 * @param {ValifiAddress} address - The address object to format.
 * @returns {string} Formatted address suitable for display in UI components
 */
export const formatAddress = (address: ValifiAddress): string => {
  const parts: string[] = [];

  // First line
  const firstLineParts: string[] = [];
  if (address.name) {
    firstLineParts.push(address.name);
  }
  if (address.subBuilding) {
    firstLineParts.push(address.subBuilding);
  }
  if (address.number) {
    firstLineParts.push(String(address.number));
  }
  if (address.street1) {
    firstLineParts.push(address.street1);
  }

  if (firstLineParts.length > 0) {
    parts.push(firstLineParts.join(" "));
  }

  // Second line - Town and county
  if (address.postTown) {
    parts.push(address.postTown);
  }

  // if (address.county) {
  //   parts.push(address.county);
  // }

  // Always add postcode at the end if available
  if (address.postcode) {
    parts.push(address.postcode);
  }

  return parts.join(", ");
};
