import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=1a9f2c9e"
import script from "./checkbox.vue?vue&type=script&lang=ts"
export * from "./checkbox.vue?vue&type=script&lang=ts"
import style0 from "./checkbox.vue?vue&type=style&index=0&id=1a9f2c9e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports