<script lang="ts">
//@ts-check
import { BuilderUtility } from "@/helpers/ClaimsGate/builder/BuilderUtility";
// eslint-disable-next-line no-unused-vars
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { Props } from "@/types";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import Vue from "vue";
const inputPropsValue = inputProps({
  answerType: "string",
  extraProps: ["text", "linkTargets", "linkTexts", "linkTarget", "linkText"],
});

delete inputPropsValue.label;
delete inputPropsValue.info;

if (inputPropsValue.linkTarget) {
  inputPropsValue.linkTargets = [inputPropsValue.linkTarget];
}

if (inputPropsValue.linkText) {
  inputPropsValue.linkTexts = [inputPropsValue.linkText];
}

export default Vue.extend({
  name: "BlockCheckbox",
  components: { BlockInputWrapper },
  props: { ...(inputPropsValue as Props.BlockCheckbox) },
  data() {
    return {
      linkTargetCopy: [],
      BuilderUtility: BuilderUtility,
    };
  },

  methods: {
    setAnswerUndefined() {
      this.$emit("update:answer", "");
    },

    handleLinkClick(event) {
      // If checkbox is not checked, check it and prevent link navigation
      if (this.answer !== "accepted") {
        event.preventDefault();
        this.$emit("update:answer", "accepted");
        this.$emit("update:state", null);
      }
      // If already checked, allow default behavior (follow the link)
    },
  },
  mounted() {
    if (this.linkTargets) {
      this.linkTargets.forEach((linkTarget) => {
        console.log("linkTarget", linkTarget);
        this.linkTargetCopy.push(BuilderUtility.parseUrl(linkTarget));
      });
    }
  },

  watch: {
    linkTarget: {
      handler(newLink) {
        this.linkTargetCopy = BuilderUtility.parseUrl(newLink);
      },
    },
    answer: function (newVal) {
      if (newVal === "not_accepted") {
        this.setAnswerUndefined();
      }
    },
  },
  computed: {
    ...themesComputed,
    dataCyLabel(): string {
      return `checkbox-${this.text.toLowerCase().replace(/\s/g, "")}`;
    },
  },
});
</script>

<template>
  <BlockInputWrapper
    :padding="padding"
    :invalidFeedback="invalidFeedback"
    :state="state"
    :info="undefined"
    :label="undefined"
  >
    <div class="mt-1">
      <b-form-checkbox
        @change="
          $emit('update:answer', $event);
          $emit('update:state', null);
        "
        :checked="answer"
        :state="state"
        :require="required"
        :disabled="disabled"
        value="accepted"
        unchecked-value="not_accepted"
        :size="theme.size"
        class="x126 checkbox-with-text"
        :ref="'b-checkbox'"
        v-bind:style="[$route.params.workspaceId && theme.primaryStyles ? theme.primaryStyles : {}]"
        :data-cy="`checkbox-${dataCyLabel}`"
      >
        <span class="tw-ml-3 tw-inline-block">
          <template v-for="(linkTarget, index) in linkTargets">
            <span
              v-if="linkTarget && linkTarget.length > 0"
              class="font-weight-normal"
              :style="theme.textColourStyles"
              :key="index"
            >
              <span v-if="index > 0"> and </span>

              <span v-if="index === 0">
                {{
                  $te("blocks.contactDetails." + (index > 0 ? text.toLowerCase() : text))
                    ? $t("blocks.contactDetails." + (index > 0 ? text.toLowerCase() : text))
                    : index > 0
                    ? text
                    : text
                }}
              </span>
              <a :href="linkTarget" target="_blank" class="tw-font-semibold" @click="handleLinkClick($event)">
                {{
                  $te("blocks.contactDetails." + linkTexts[index])
                    ? $t("blocks.contactDetails." + linkTexts[index])
                    : linkTexts[index]
                }}
              </a>
            </span>
          </template>
          <span v-if="!linkTargets" class="font-weight-normal" :style="theme.textColourStyles">
            {{ $te("blocks.contactDetails." + text) ? $t("blocks.contactDetails." + text) : text }}
            <a v-if="linkTarget" :href="linkTarget" target="_blank" @click="handleLinkClick($event)"
              >{{ $te("blocks.contactDetails." + linkText) ? $t("blocks.contactDetails." + linkText) : linkText }}
            </a>
          </span>
        </span>
      </b-form-checkbox>
    </div>
  </BlockInputWrapper>
</template>

<style>
/* Flexbox alignment for switch and text */
.checkbox-with-text .custom-control-label {
  display: flex !important;
  align-items: center !important;
  min-height: 24px !important;
}

/* Set the proper vertical alignment */
.checkbox-with-text.custom-checkbox .custom-control-label::before,
.checkbox-with-text.custom-checkbox .custom-control-label::after {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
</style>
