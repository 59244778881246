<script lang="ts">
import Vue, { VueConstructor } from "vue";
// Components
import BlockInput from "@/components/shared/blocks/input.vue";
//import BlockButton from "@/components/shared/blocks/button.vue";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";
// Helpers
import { gatewayHelper } from "../helpers/gatewayHelper";
import { viewSubmit } from "../helpers/viewSubmit";
import { baseProps } from "../helpers/baseProps";
import { handleUnknownError } from "../helpers/handleUnknownError";
import { getUserDetailsFromCheck } from "../helpers/getUserDetailsFromCheck";

// Types
import type { AddressInput } from "../helpers/CheckboardCreditCheck";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { themesComputed } from "@/state/helpers";
export default (Vue as VueConstructor<Vue & AddressInput.Refs>).extend({
  name: "AddressInput",
  components: { BlockInput, CheckboardHeaderContent },
  props: {
    ...baseProps(),
  },
  computed: {
    ...themesComputed,
    user(): ReturnType<typeof getUserDetailsFromCheck> {
      return getUserDetailsFromCheck(this.check);
    },
  },
  data() {
    return {
      isSearching: false,
      invalidFeedback: "",
      postcode: "",
      BlockProps: {
        postcodeSearch: UserVariableBlocksService.genBlockInputProps({
          label: "Please enter your postcode",
          padding: "0",
          placeholder: "Enter your postcode",
        }),
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.BlockProps.postcodeSearch.invalidFeedback = "";
        this.BlockProps.postcodeSearch.state = null;

        const postcode = this.postcode.replace(/\s/g, "");

        if (postcode.length < 5 || postcode.length > 7) {
          this.BlockProps.postcodeSearch.state = false;

          this.BlockProps.postcodeSearch.invalidFeedback =
            "Please enter a valid UK postcode, between 5 and 7 characters long.";
        } else {
          this.isSearching = true;

          const data = {
            claimId: this.claimId,
            userId: this.userId,
            checkId: this.checkId,
            postcode,
          };

          const result = await gatewayHelper("checkboardAddressLookup", data);

          if (result.addresses.length === 0) {
            this.BlockProps.postcodeSearch.state = false;
            this.BlockProps.postcodeSearch.invalidFeedback =
              "No addresses found for this postcode. Please check your postcode and try again.";
          } else {
            this.BlockProps.postcodeSearch.state = true;
            console.log("Address lookup result:", result);
            viewSubmit(this, "addressInput", result);
          }
        }
      } catch (error) {
        console.error("Error submitting postcode:", error);
        handleUnknownError(this, this.check);
      } finally {
        this.isSearching = false;
      }
    },
    handleGoToFailView() {
      viewSubmit(this, "addressInputToFail", { check: this.check, clientView: "fail" });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
.hint-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
</style>
<template>
  <div class="d-flex flex-column gap-20">
    <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :progressValue="'45'"
      :hideProgressBar="hideProgressBar"
      :header="'Address Search'"
      :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, please confirm your current address.`"
    />

    <BlockInput v-bind="BlockProps.postcodeSearch" :answer.sync="postcode">
      <template v-slot:form-input>
        <template>
          <b-input-group-append>
            <b-button
              @click="submit()"
              :variant="'primary'"
              class="text-white"
              :size="BlockProps.postcodeSearch.size"
              :disabled="BlockProps.postcodeSearch.disabled"
              :class="{ 'btn-gray': BlockProps.postcodeSearch.disabled || isSearching }"
              data-cy="kyc-postcode-search-button"
            >
              <template v-if="isSearching">
                <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
              </template>
              <template v-else> Search </template>
            </b-button>
          </b-input-group-append>
        </template>
      </template>
    </BlockInput>
    <div>
      <h5 class="font-weight-normal" role="button" @click="handleGoToFailView">
        <span class="text-sm lh-base font-weight-semibold text-primary-700">Can't find you address? Click here.</span>
      </h5>
    </div>

    <!--<p class="text-center text-sm mx-auto mt-2 mb-0 text-red-500" v-if="invalidFeedback">{{ invalidFeedback }}</p>

    <BlockButton
      :text="'Search'"
      :variant="'primary'"
      :isProcessing="isSearching"
      @clicked="submit"
      :block="false"
      :submitButtonDefault="false"
    />-->
  </div>
</template>
