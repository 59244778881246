<script lang="ts">
import Vue from "vue";

import { navigationComputed, eventComputed, themesComputed, formComputed } from "@/state/helpers";

export default Vue.extend({
  name: "InitiateCheck",

  mounted() {
    window.scrollTo(0, 0);
  },

  computed: {
    ...navigationComputed,
    ...eventComputed,
    ...themesComputed,
    ...formComputed,
  },
});
</script>

<style scoped>
/* .vh-100 {
  height: 100vh;
} */
</style>

<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="d-flex align-items-center justify-content-center">
      <b-icon class="fa fa-smog" animation="cylon" font-scale="3.75" :style="[{ color: theme.primaryColour }]" />
      <b-icon class="fa fa-car-side" animation="cylon" font-scale="4" :style="[{ color: theme.primaryColour }]" />
    </div>
    <span class="text-center text-primary font-weight-semibold text-xl mt-2">Finding Your Agreements...</span>
  </div>
</template>
