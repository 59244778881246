import { blocks } from "./blocks";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { InfoModalService } from "../../global/informationModal/InfoModalService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";

export function getData(state: Vue) {
  return {
    win: null as string | null,
    make: null as string | null,
    model: null as string | null,
    uiToggles: {
      isLoading: false,
    },
    vehicleDataSource: null as string | null,
    userId: null as string,
    claimId: state.$route.params.claimId,
    pageId: state.$route.params.pageId,
    funnelId: state.$route.params.funnelId,
    userService: getUserHelper(),
    claimDataService: null as ClaimDataService | null,
    infoModalService: new InfoModalService(state.$infoModal),
    BlockInputs: blocks,
    variableService: new VariableService(),
    currentView: "vehicle" as "vehicle" | "date",
  };
}
