<script lang="ts">
import Vue from "vue";
import { themesComputed } from "@/state/helpers";
import { faShieldCheck } from "@fortawesome/pro-regular-svg-icons/faShieldCheck";
import { faThumbsUp } from "@fortawesome/pro-regular-svg-icons/faThumbsUp";
import { faClipboardCheck } from "@fortawesome/pro-regular-svg-icons/faClipboardCheck";

const icons = {
  faShieldCheck,
  faThumbsUp,
  faClipboardCheck,
};
export default Vue.extend({
  name: "CheckboardAboutCard",
  components: {},
  computed: {
    ...themesComputed,
  },
  data() {
    return {
      collapseShowing: false,
      textProps: {
        styles: "h6",
        align: "left",
        weight: "normal",
        text: "",
        padding: "0",
      },
      verificationHelp: [
        {
          icon: icons.faShieldCheck,
          html: "<strong>Confirm Details:</strong> We'll verify the specifics of your financial agreement.",
          iconClass: "text-success icon-size",
        },
        {
          icon: icons.faClipboardCheck,
          html: "<strong>Secure Process:</strong> Your information is handled confidentially and securely.",
          iconClass: "text-primary icon-size",
        },
        {
          icon: icons.faThumbsUp,
          html: "<strong>Strengthen Your Claim:</strong> Verifying these details will help us build a stronger case for you.",
          iconClass: "text-success icon-size",
        },
      ],
      processHelp: [
        {
          html: "<strong>Agreement Details:</strong> We'll confirm the details of the agreement related to your claim.",
        },
        {
          html: "<strong>Personal Information:</strong> We'll verify your name and address to ensure all information is correct.",
        },
        {
          html: "<strong>Claim Assessment:</strong> We'll check for any other elements that might affect your claim.",
        },
        {
          html: "<strong>Secure Data Handling:</strong> All information is processed securely and used solely for your claim.",
        },
        {
          html: "<strong>Limited Duration:</strong> This permission is only valid for the duration of processing your claim.",
        },
      ],
    };
  },
  methods: {
    toggleCollapse() {
      this.collapseShowing = !this.collapseShowing;
    },
  },
});
</script>
<style scoped>
.gap-16 {
  gap: 16px;
}
.gap-8 {
  gap: 8px;
}
.underlineOnHover:hover {
  text-decoration: underline;
}
.icon-size {
  width: 16px;
  height: 20px;
}
.w-24 {
  width: 24px;
  height: 24px;
}
</style>
<template>
  <b-card class="p-0 m-0 shadow-none rounded-0 text-left">
    <div class="d-flex flex-column gap-16">
      <div>
        <h5 class="text-grey-900 font-weight-semibold text-md">How This Verification Helps You</h5>
      </div>
      <div class="d-flex flex-column gap-16">
        <div class="d-flex flex-row gap-8" v-for="item in verificationHelp" :key="item.html">
          <div class="align-content-center justify-content-center px-1">
            <font-awesome-icon :icon="item.icon" :class="item.iconClass" />
          </div>
          <div class="align-content-center">
            <h5 :style="[theme.fontStyles]" class="text-sm text-grey-700 font-weight-normal mb-0" v-html="item.html" />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column gap-8">
        <div v-b-toggle="'collapse'" class="d-flex" @click="toggleCollapse()">
          <div class="d-flex justify-content-between align-items-center w-100 pr-4">
            <h5 class="text-grey-900 font-weight-semibold text-sm">What Our Process Involves</h5>
            <b-icon v-if="collapseShowing" icon="chevron-up" class="ml-auto"></b-icon>
            <b-icon v-else icon="chevron-down" class="d-block ml-auto"></b-icon>
          </div>
        </div>
        <b-collapse :id="'collapse'" class="mt-2">
          <div class="d-flex flex-column gap-16">
            <div class="d-flex flex-row gap-8" v-for="(item, index) in processHelp" :key="item.html">
              <div class="align-content-start justify-content-center px-1 w-24 text-center">
                <h5 :style="[theme.fontStyles]" class="text-sm text-grey-700 font-weight-normal mb-0">
                  {{ index + 1 }}.
                </h5>
              </div>
              <div class="align-content-center">
                <h5
                  :style="[theme.fontStyles]"
                  class="text-sm text-grey-700 font-weight-normal mb-0"
                  v-html="item.html"
                />
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </b-card>
</template>
