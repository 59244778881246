<script lang="ts">
import Vue from "vue";
import BlockProgress from "@/components/shared/blocks/progress.vue";
import BlockText from "@/components/shared/blocks/text/text.vue";
import BlockTitle from "@/components/shared/blocks/text/title.vue";
import BlockSubtitle from "@/components/shared/blocks/text/subtitle.vue";
import { themesComputed } from "@/state/helpers";

export default Vue.extend({
  name: "CheckboardHeaderContent",
  components: {
    BlockProgress,
    BlockTitle,
    BlockSubtitle,
    BlockText,
  },
  props: {
    isBuilder: {
      type: Boolean,
      default: false,
    },
    progressValue: {
      type: [String],
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    hideProgressBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...themesComputed,
  },
});
</script>
<style scoped>
.gap-8 {
  gap: 8px;
}
</style>
<template>
  <div class="d-flex flex-column gap-8">
    <!-- progress bar -->
    <div
      class="flex-column align-items-center justify-content-center pb-4"
      v-if="!!progressValue && progressValue !== '0' && !hideProgressBar"
    >
      <BlockProgress
        v-bind="{
          value: progressValue,
          variant: 'primary',
          height: '10',
          animated: false,
          width: 'sm',
          align: 'center',
        }"
      />
      <BlockText
        :text="'Agreement Verification ' + progressValue + '%'"
        :isBuilder="isBuilder"
        :weight="'bold'"
        :styles="'h5'"
        :align="'center'"
        :textColour="'text-primary'"
        :padding="'10'"
      />
    </div>
    <!-- header -->
    <div v-if="header">
      <BlockTitle :text="header" :styles="'h3'" :align="'left'" :weight="'bold'" :textColour="'text-primary'" />
    </div>
    <!-- subtitle -->
    <div v-if="subtitle" class="mt-2">
      <BlockSubtitle
        :text="subtitle"
        :styles="'h5'"
        :align="'left'"
        :weight="'semibold'"
        :textColour="'text-body'"
        :padding="'0'"
      >
        <slot name="subtitle"></slot>
      </BlockSubtitle>
    </div>
    <!-- text -->
    <div v-if="text">
      <h5
        :style="[theme.fontStyles, theme.primaryStyles]"
        class="text-sm text-grey-500 font-weight-semibold"
        v-html="text"
      ></h5>
    </div>
  </div>
</template>
