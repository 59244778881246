<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
// Components
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";
// Helpers
import { gatewayHelper } from "../helpers/gatewayHelper";
import { viewSubmit } from "../helpers/viewSubmit";
import { baseProps } from "../helpers/baseProps";
import { formatAddress } from "../helpers/formatAddress";
import { handleUnknownError } from "../helpers/handleUnknownError";
import { getUserDetailsFromCheck } from "../helpers/getUserDetailsFromCheck";
// Types
import type { AddressConfirmation } from "../helpers/CheckboardCreditCheck";
import type { ValifiAddress } from "@claimsgate/core-types";
import { themesComputed } from "@/state/helpers";
export default (Vue as VueConstructor<Vue & AddressConfirmation.Refs>).extend({
  name: "AddressConfirmation",
  components: { BlockDropdown, BlockButton, CheckboardHeaderContent },
  props: {
    ...baseProps(),
    addresses: {
      type: Array as PropType<ValifiAddress[]>,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
      selectedAddress: null as ValifiAddress | null,
    };
  },
  computed: {
    ...themesComputed,
    dropdownOptions(): string[] {
      return this.addresses.map((address) => this.formatAddress(address));
    },
    user(): ReturnType<typeof getUserDetailsFromCheck> {
      return getUserDetailsFromCheck(this.check);
    },
  },
  methods: {
    formatAddress,
    handleAddressSelect(address: string) {
      this.selectedAddress = this.addresses.find((addressEntry) => this.formatAddress(addressEntry) === address);
    },

    async handleRequestKbaQuestions() {
      try {
        this.isSubmitting = true;

        const data = {
          claimId: this.claimId,
          userId: this.userId,
          checkId: this.checkId,
          addressHistory: [this.selectedAddress],
        };

        const result = await gatewayHelper("valifiRequestKba", data);

        viewSubmit(this, "addressConfirmation", result);
      } catch (error) {
        console.error("YES route error", error);
        handleUnknownError(this, this.check);
      } finally {
        this.isSubmitting = false;
      }
    },
    // TODO: implement handling for users address history
    handleGoToHistoryView() {
      viewSubmit(
        this,
        "addressConfirmationToHistory",
        { check: this.check, clientView: "addressHistory" },
        this.selectedAddress
      );
    },
    goToAddressInput() {
      viewSubmit(this, "addressConfirmationToAddressInput", { check: this.check, clientView: "addressInput" });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
.gap-8 {
  gap: 8px;
}
</style>

<template>
  <div class="d-flex flex-column gap-20">
    <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :progressValue="'55'"
      :hideProgressBar="hideProgressBar"
      :header="'Address Confirmation'"
      :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, please select your address from the list below.`"
    />

    <div class="d-flex flex-column gap-8">
      <BlockDropdown
        v-bind="{
          label: 'Select your address',
          placeholder: 'Select an address',
          options: dropdownOptions,
          ref: 'dropdown',
        }"
        @update:answer="handleAddressSelect"
      />
    </div>
    <!-- Deprecating address history view for now -->
    <!--<div v-if="selectedAddress" class="mx-auto text-center">
      <p class="mt-4 mb-2 text-lg">Have to been living here for the past 3 years?</p>
      <ClaimsGateButton
        variant="primary"
        text="Yes"
        @click="handleCallRunCheck"
        :loading="isSubmitting"
        loadingText=""
      />
      <ClaimsGateButton variant="secondary-grey" class="ml-2" text="No" @click="handleGoToHistoryView" />
    </div>-->
    <BlockButton
      :text="'Continue'"
      :variant="'primary'"
      :isProcessing="isSubmitting"
      @clicked="handleRequestKbaQuestions"
      :block="false"
      :submitButtonDefault="false"
      data-cy="checkboard-credit-check-continue-button"
    />
  </div>
</template>
