// Array of country objects for the flag dropdown.

// Here is the criteria for the plugin to support a given country/territory
// - It has an iso2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
// - It has it's own country calling code: https://en.wikipedia.org/wiki/List_of_country_calling_codes
// - It has a flag in the region-flags project: https://github.com/behdad/region-flags/tree/gh-pages/png
// - It is supported by libphonenumber: https://github.com/googlei18n/libphonenumber/blob/master/resources/ShortNumberMetadata.xml

// Each country array has the following information:
// [
//    Country name,
//    Country code ISO 3166-1 alpha-2,
//    International dial code
//    Currency code ISO 4217,
//    Currency symbol,
//    Currency name
// ]

const allCountriesDataset = [
  ["Afghanistan", "af", "93", "AFN", "؋", "Afghan afghani"],
  ["Albania", "al", "355", "ALL", "L", "Albanian lek"],
  ["Algeria", "dz", "213", "DZD", "د.ج", "Algerian dinar"],
  ["American Samoa", "as", "1684", "USD", "$", "United States dollar"],
  ["Andorra", "ad", "376", "EUR", "€", "Euro"],
  ["Angola", "ao", "244", "AOA", "Kz", "Angolan kwanza"],
  ["Anguilla", "ai", "1264", "XCD", "$", "East Caribbean dollar"],
  ["Antigua and Barbuda", "ag", "1268", "XCD", "$", "East Caribbean dollar"],
  ["Argentina", "ar", "54", "ARS", "$", "Argentine peso"],
  ["Armenia", "am", "374", "AMD", "֏", "Armenian dram"],
  ["Aruba", "aw", "297", "AWG", "ƒ", "Aruban florin"],
  ["Australia", "au", "61", "AUD", "$", "Australian dollar"],
  ["Austria", "at", "43", "EUR", "€", "Euro"],
  ["Azerbaijan", "az", "994", "AZN", "₼", "Azerbaijani manat"],
  ["Bahamas", "bs", "1242", "BSD", "$", "Bahamian dollar"],
  ["Bahrain", "bh", "973", "BHD", ".د.ب", "Bahraini dinar"],
  ["Bangladesh", "bd", "880", "BDT", "৳", "Bangladeshi taka"],
  ["Barbados", "bb", "1246", "BBD", "$", "Barbadian dollar"],
  ["Belarus", "by", "375", "BYN", "Br", "Belarusian ruble"],
  ["Belgium", "be", "32", "EUR", "€", "Euro"],
  ["Belize", "bz", "501", "BZD", "$", "Belize dollar"],
  ["Benin", "bj", "229", "XOF", "Fr", "West African CFA franc"],
  ["Bermuda", "bm", "1441", "BMD", "$", "Bermudian dollar"],
  ["Bhutan", "bt", "975", "BTN", "Nu.", "Bhutanese ngultrum"],
  ["Bolivia", "bo", "591", "BOB", "Bs.", "Bolivian boliviano"],
  ["Bosnia and Herzegovina", "ba", "387", "BAM", "KM", "Bosnia and Herzegovina convertible mark"],
  ["Botswana", "bw", "267", "BWP", "P", "Botswana pula"],
  ["Brazil", "br", "55", "BRL", "R$", "Brazilian real"],
  ["British Indian Ocean Territory", "io", "246", "USD", "$", "United States dollar"],
  ["British Virgin Islands", "vg", "1284", "USD", "$", "United States dollar"],
  ["Brunei", "bn", "673", "BND", "$", "Brunei dollar"],
  ["Bulgaria", "bg", "359", "BGN", "лв", "Bulgarian lev"],
  ["Burkina Faso", "bf", "226", "XOF", "Fr", "West African CFA franc"],
  ["Burundi", "bi", "257", "BIF", "Fr", "Burundian franc"],
  ["Cambodia", "kh", "855", "KHR", "៛", "Cambodian riel"],
  ["Cameroon", "cm", "237", "XAF", "Fr", "Central African CFA franc"],
  ["Canada", "ca", "1", "CAD", "$", "Canadian dollar"],
  ["Cape Verde", "cv", "238", "CVE", "Esc", "Cape Verdean escudo"],
  ["Caribbean Netherlands", "bq", "599", "USD", "$", "United States dollar"],
  ["Cayman Islands", "ky", "1345", "KYD", "$", "Cayman Islands dollar"],
  ["Central African Republic", "cf", "236", "XAF", "Fr", "Central African CFA franc"],
  ["Chad", "td", "235", "XAF", "Fr", "Central African CFA franc"],
  ["Chile", "cl", "56", "CLP", "$", "Chilean peso"],
  ["China", "cn", "86", "CNY", "¥", "Chinese yuan"],
  ["Christmas Island", "cx", "61", "AUD", "$", "Australian dollar"],
  ["Coco Islands", "cc", "61", "AUD", "$", "Australian dollar"],
  ["Colombia", "co", "57", "COP", "$", "Colombian peso"],
  ["Comoros", "km", "269", "KMF", "Fr", "Comorian franc"],
  ["Congo (DRC)", "cd", "243", "CDF", "Fr", "Congolese franc"],
  ["Congo (Republic)", "cg", "242", "XAF", "Fr", "Central African CFA franc"],
  ["Cook Islands", "ck", "682", "NZD", "$", "New Zealand dollar"],
  ["Costa Rica", "cr", "506", "CRC", "₡", "Costa Rican colón"],
  ["Côte d’Ivoire", "ci", "225", "XOF", "Fr", "West African CFA franc"],
  ["Croatia", "hr", "385", "HRK", "kn", "Croatian kuna"],
  ["Cuba", "cu", "53", "CUP", "$", "Cuban peso"],
  ["Curaçao", "cw", "599", "ANG", "ƒ", "Netherlands Antillean guilder"],
  ["Cyprus", "cy", "357", "EUR", "€", "Euro"],
  ["Czech Republic", "cz", "420", "CZK", "Kč", "Czech koruna"],
  ["Denmark", "dk", "45", "DKK", "kr", "Danish krone"],
  ["Djibouti", "dj", "253", "DJF", "Fr", "Djiboutian franc"],
  ["Dominica", "dm", "1767", "XCD", "$", "East Caribbean dollar"],
  ["Dominican Republic", "do", "1", "DOP", "$", "Dominican peso"],
  ["Ecuador", "ec", "593", "USD", "$", "United States dollar"],
  ["Egypt", "eg", "20", "EGP", "£", "Egyptian pound"],
  ["El Salvador", "sv", "503", "USD", "$", "United States dollar"],
  ["Equatorial Guinea", "gq", "240", "XAF", "Fr", "Central African CFA franc"],
  ["Eritrea", "er", "291", "ERN", "Nfk", "Eritrean nakfa"],
  ["Estonia", "ee", "372", "EUR", "€", "Euro"],
  ["Ethiopia", "et", "251", "ETB", "Br", "Ethiopian birr"],
  ["Falkland Islands", "fk", "500", "FKP", "£", "Falkland Islands pound"],
  ["Faroe Islands", "fo", "298", "DKK", "kr", "Danish krone"],
  ["Fiji", "fj", "679", "FJD", "$", "Fijian dollar"],
  ["Finland", "fi", "358", "EUR", "€", "Euro"],
  ["France", "fr", "33", "EUR", "€", "Euro"],
  ["French Guiana", "gf", "594", "EUR", "€", "Euro"],
  ["French Polynesia", "pf", "689", "XPF", "Fr", "CFP franc"],
  ["Gabon", "ga", "241", "XAF", "Fr", "Central African CFA franc"],
  ["Gambia", "gm", "220", "GMD", "D", "Gambian dalasi"],
  ["Georgia", "ge", "995", "GEL", "ლ", "Georgian lari"],
  ["Germany", "de", "49", "EUR", "€", "Euro"],
  ["Ghana", "gh", "233", "GHS", "₵", "Ghanaian cedi"],
  ["Gibraltar", "gi", "350", "GIP", "£", "Gibraltar pound"],
  ["Greece", "gr", "30", "EUR", "€", "Euro"],
  ["Greenland", "gl", "299", "DKK", "kr", "Danish krone"],
  ["Grenada", "gd", "1473", "XCD", "$", "East Caribbean dollar"],
  ["Guadeloupe", "gp", "590", "EUR", "€", "Euro"],
  ["Guam", "gu", "1671", "USD", "$", "United States dollar"],
  ["Guatemala", "gt", "502", "GTQ", "Q", "Guatemalan quetzal"],
  ["Guernsey", "gg", "44", 1, "GBP", "£", "British pound sterling"],
  ["Guinea", "gn", "224", "GNF", "Fr", "Guinean franc"],
  ["Guinea-Bissau", "gw", "245", "XOF", "Fr", "West African CFA franc"],
  ["Guyana", "gy", "592", "GYD", "$", "Guyanese dollar"],
  ["Haiti", "ht", "509", "HTG", "G", "Haitian gourde"],
  ["Honduras", "hn", "504", "HNL", "L", "Honduran lempira"],
  ["Hong Kong", "hk", "852", "HKD", "$", "Hong Kong dollar"],
  ["Hungary", "hu", "36", "HUF", "Ft", "Hungarian forint"],
  ["Iceland", "is", "354", "ISK", "kr", "Icelandic króna"],
  ["India", "in", "91", "INR", "₹", "Indian rupee"],
  ["Indonesia", "id", "62", "IDR", "Rp", "Indonesian rupiah"],
  ["Iran", "ir", "98", "IRR", "﷼", "Iranian rial"],
  ["Iraq", "iq", "964", "IQD", "ع.د", "Iraqi dinar"],
  ["Ireland", "ie", "353", "EUR", "€", "Euro"],
  ["Isle of Man", "im", "44", 1, "GBP", "£", "British pound sterling"],
  ["Israel", "il", "972", "ILS", "₪", "Israeli new shekel"],
  ["Italy", "it", "39", "EUR", "€", "Euro"],
  ["Jamaica", "jm", "1876", "JMD", "$", "Jamaican dollar"],
  ["Japan", "jp", "81", "JPY", "¥", "Japanese yen"],
  ["Jersey", "je", "44", 1, "GBP", "£", "British pound sterling"],
  ["Jordan", "jo", "962", "JOD", "د.ا", "Jordanian dinar"],
  ["Kazakhstan", "kz", "7", "KZT", "₸", "Kazakhstani tenge"],
  ["Kenya", "ke", "254", "KES", "KSh", "Kenyan shilling"],
  ["Kiribati", "ki", "686", "AUD", "$", "Australian dollar"],
  ["Kosovo", "xk", "383", "EUR", "€", "Euro"],
  ["Kuwait", "kw", "965", "KWD", "د.ك", "Kuwaiti dinar"],
  ["Kyrgyzstan", "kg", "996", "KGS", "с", "Kyrgyzstani som"],
  ["Laos", "la", "856", "LAK", "₭", "Lao kip"],
  ["Latvia", "lv", "371", "EUR", "€", "Euro"],
  ["Lebanon", "lb", "961", "LBP", "ل.ل", "Lebanese pound"],
  ["Lesotho", "ls", "266", "LSL", "L", "Lesotho loti"],
  ["Liberia", "lr", "231", "LRD", "$", "Liberian dollar"],
  ["Libya", "ly", "218", "LYD", "ل.د", "Libyan dinar"],
  ["Liechtenstein", "li", "423", "CHF", "Fr", "Swiss franc"],
  ["Lithuania", "lt", "370", "EUR", "€", "Euro"],
  ["Luxembourg", "lu", "352", "EUR", "€", "Euro"],
  ["Macau", "mo", "853", "MOP", "MOP$", "Macanese pataca"],
  ["Macedonia", "mk", "389", "MKD", "ден", "Macedonian denar"],
  ["Madagascar", "mg", "261", "MGA", "Ar", "Malagasy ariary"],
  ["Malawi", "mw", "265", "MWK", "MK", "Malawian kwacha"],
  ["Malaysia", "my", "60", "MYR", "RM", "Malaysian ringgit"],
  ["Maldives", "mv", "960", "MVR", "ރ.", "Maldivian rufiyaa"],
  ["Mali", "ml", "223", "XOF", "Fr", "West African CFA franc"],
  ["Malta", "mt", "356", "EUR", "€", "Euro"],
  ["Marshall Islands", "mh", "692", "USD", "$", "United States dollar"],
  ["Martinique", "mq", "596", "EUR", "€", "Euro"],
  ["Mauritania", "mr", "222", "MRO", "UM", "Mauritanian ouguiya"],
  ["Mauritius", "mu", "230", "MUR", "₨", "Mauritian rupee"],
  ["Mayotte", "yt", "262", "EUR", "€", "Euro"],
  ["Mexico", "mx", "52", "MXN", "$", "Mexican peso"],
  ["Micronesia", "fm", "691", "USD", "$", "United States dollar"],
  ["Moldova", "md", "373", "MDL", "L", "Moldovan leu"],
  ["Monaco", "mc", "377", "EUR", "€", "Euro"],
  ["Mongolia", "mn", "976", "MNT", "₮", "Mongolian tögrög"],
  ["Montenegro", "me", "382", "EUR", "€", "Euro"],
  ["Montserrat", "ms", "1664", "XCD", "$", "East Caribbean dollar"],
  ["Morocco", "ma", "212", "MAD", "د.م.", "Moroccan dirham"],
  ["Mozambique", "mz", "258", "MZN", "MT", "Mozambican metical"],
  ["Myanmar", "mm", "95", "MMK", "Ks", "Burmese kyat"],
  ["Namibia", "na", "264", "NAD", "$", "Namibian dollar"],
  ["Nauru", "nr", "674", "AUD", "$", "Australian dollar"],
  ["Nepal", "np", "977", "NPR", "₨", "Nepalese rupee"],
  ["Netherlands", "nl", "31", "EUR", "€", "Euro"],
  ["New Caledonia", "nc", "687", "XPF", "Fr", "CFP franc"],
  ["New Zealand", "nz", "64", "NZD", "$", "New Zealand dollar"],
  ["Nicaragua", "ni", "505", "NIO", "C$", "Nicaraguan córdoba"],
  ["Niger", "ne", "227", "XOF", "Fr", "West African CFA franc"],
  ["Nigeria", "ng", "234", "NGN", "₦", "Nigerian naira"],
  ["Niue", "nu", "683", "NZD", "$", "New Zealand dollar"],
  ["Norfolk Island", "nf", "672", "AUD", "$", "Australian dollar"],
  ["North Korea", "kp", "850", "KPW", "₩", "North Korean won"],
  ["Northern Mariana Islands", "mp", "1670", "USD", "$", "United States dollar"],
  ["Norway", "no", "47", "NOK", "kr", "Norwegian krone"],
  ["Oman", "om", "968", "OMR", "ر.ع.", "Omani rial"],
  ["Pakistan", "pk", "92", "PKR", "₨", "Pakistani rupee"],
  ["Palau", "pw", "680", "USD", "$", "United States dollar"],
  ["Palestine", "ps", "970", "ILS", "₪", "Israeli new shekel"],
  ["Panama", "pa", "507", "PAB", "B/.", "Panamanian balboa"],
  ["Papua New Guinea", "pg", "675", "PGK", "K", "Papua New Guinean kina"],
  ["Paraguay", "py", "595", "PYG", "₲", "Paraguayan guaraní"],
  ["Peru", "pe", "51", "PEN", "S/.", "Peruvian sol"],
  ["Philippines", "ph", "63", "PHP", "₱", "Philippine peso"],
  ["Poland", "pl", "48", "PLN", "zł", "Polish złoty"],
  ["Portugal", "pt", "351", "EUR", "€", "Euro"],
  ["Puerto Rico", "pr", "1", "USD", "$", "United States dollar"],
  ["Qatar", "qa", "974", "QAR", "ر.ق", "Qatari riyal"],
  ["Réunion", "re", "262", "EUR", "€", "Euro"],
  ["Romania", "ro", "40", "RON", "lei", "Romanian leu"],
  ["Russia", "ru", "7", "RUB", "₽", "Russian ruble"],
  ["Rwanda", "rw", "250", "RWF", "Fr", "Rwandan franc"],
  ["Saint Barthélemy", "bl", "590", "EUR", "€", "Euro"],
  ["Saint Helena", "sh", "290", "SHP", "£", "Saint Helena pound"],
  ["Saint Kitts and Nevis", "kn", "1869", "XCD", "$", "East Caribbean dollar"],
  ["Saint Lucia", "lc", "1758", "XCD", "$", "East Caribbean dollar"],
  ["Saint Martin", "mf", "590", "EUR", "€", "Euro"],
  ["Saint Pierre and Miquelon", "pm", "508", "EUR", "€", "Euro"],
  ["Saint Vincent and the Grenadines", "vc", "1784", "XCD", "$", "East Caribbean dollar"],
  ["Samoa", "ws", "685", "WST", "T", "Samoan tālā"],
  ["San Marino", "sm", "378", "EUR", "€", "Euro"],
  ["São Tomé and Príncipe", "st", "239", "STN", "Db", "São Tomé and Príncipe dobra"],
  ["Saudi Arabia", "sa", "966", "SAR", "ر.س", "Saudi riyal"],
  ["Senegal", "sn", "221", "XOF", "Fr", "West African CFA franc"],
  ["Serbia", "rs", "381", "RSD", "дин.", "Serbian dinar"],
  ["Seychelles", "sc", "248", "SCR", "₨", "Seychellois rupee"],
  ["Sierra Leone", "sl", "232", "SLL", "Le", "Sierra Leonean leone"],
  ["Singapore", "sg", "65", "SGD", "$", "Singapore dollar"],
  ["Sint Maarten", "sx", "1721", "ANG", "ƒ", "Netherlands Antillean guilder"],
  ["Slovakia", "sk", "421", "EUR", "€", "Euro"],
  ["Slovenia", "si", "386", "EUR", "€", "Euro"],
  ["Solomon Islands", "sb", "677", "SBD", "$", "Solomon Islands dollar"],
  ["Somalia", "so", "252", "SOS", "S", "Somali shilling"],
  ["South Africa", "za", "27", "ZAR", "R", "South African rand"],
  ["South Korea", "kr", "82", "KRW", "₩", "South Korean won"],
  ["South Sudan", "ss", "211", "SSP", "£", "South Sudanese pound"],
  ["Spain", "es", "34", "EUR", "€", "Euro"],
  ["Sri Lanka", "lk", "94", "LKR", "₨", "Sri Lankan rupee"],
  ["Sudan", "sd", "249", "SDG", "£", "Sudanese pound"],
  ["Suriname", "sr", "597", "SRD", "$", "Surinamese dollar"],
  ["Svalbard and Jan Mayen", "sj", "47", "NOK", "kr", "Norwegian krone"],
  ["Swaziland", "sz", "268", "SZL", "L", "Swazi lilangeni"],
  ["Sweden", "se", "46", "SEK", "kr", "Swedish krona"],
  ["Switzerland", "ch", "41", "CHF", "Fr", "Swiss franc"],
  ["Syria", "sy", "963", "SYP", "£", "Syrian pound"],
  ["Taiwan", "tw", "886", "TWD", "NT$", "New Taiwan dollar"],
  ["Tajikistan", "tj", "992", "TJS", "ЅМ", "Tajikistani somoni"],
  ["Tanzania", "tz", "255", "TZS", "Sh", "Tanzanian shilling"],
  ["Thailand", "th", "66", "THB", "฿", "Thai baht"],
  ["Timor-Leste", "tl", "670", "USD", "$", "United States dollar"],
  ["Togo", "tg", "228", "XOF", "Fr", "West African CFA franc"],
  ["Tokelau", "tk", "690", "NZD", "$", "New Zealand dollar"],
  ["Tonga", "to", "676", "TOP", "T$", "Tongan paʻanga"],
  ["Trinidad and Tobago", "tt", "1868", "TTD", "$", "Trinidad and Tobago dollar"],
  ["Tunisia", "tn", "216", "TND", "د.ت", "Tunisian dinar"],
  ["Turkey", "tr", "90", "TRY", "₺", "Turkish lira"],
  ["Turkmenistan", "tm", "993", "TMT", "m", "Turkmenistan manat"],
  ["Turks and Caicos Islands", "tc", "1649", "USD", "$", "United States dollar"],
  ["Tuvalu", "tv", "688", "AUD", "$", "Australian dollar"],
  ["U.S. Virgin Islands", "vi", "1340", "USD", "$", "United States dollar"],
  ["Uganda", "ug", "256", "UGX", "USh", "Ugandan shilling"],
  ["Ukraine", "ua", "380", "UAH", "₴", "Ukrainian hryvnia"],
  ["United Arab Emirates", "ae", "971", "AED", "د.إ", "United Arab Emirates dirham"],
  ["United Kingdom", "gb", "44", "GBP", "£", "British pound"],
  ["United States", "us", "1", "USD", "$", "United States dollar"],
  ["Uruguay", "uy", "598", "UYU", "$", "Uruguayan peso"],
  ["Uzbekistan", "uz", "998", "UZS", "лв", "Uzbekistani soʻm"],
  ["Vanuatu", "vu", "678", "VUV", "VT", "Vanuatu vatu"],
  ["Vatican City", "va", "39", "EUR", "€", "Euro"],
  ["Venezuela", "ve", "58", "VES", "Bs.S", "Venezuelan bolívar"],
  ["Vietnam", "vn", "84", "VND", "₫", "Vietnamese đồng"],
  ["Wallis and Futuna", "wf", "681", "XPF", "Fr", "CFP franc"],
  ["Western Sahara", "eh", "212", "MAD", "د.م.", "Moroccan dirham"],
  ["Yemen", "ye", "967", "YER", "﷼", "Yemeni rial"],
  ["Zambia", "zm", "260", "ZMW", "ZK", "Zambian kwacha"],
  ["Zimbabwe", "zw", "263", "ZWL", "$", "Zimbabwean dollar"],
  ["Åland Islands", "ax", "358", "EUR", "€", "Euro"],
];

export const internationalCallingCodes = allCountriesDataset.map(([name, iso2, dialCode]) => ({
  name,
  iso2: iso2.toUpperCase(),
  dialCode,
}));

export const internationalCurrencyCodes = allCountriesDataset.map(
  ([name, iso2, , currencyCode, currencySymbol, currencyName]) => ({
    name,
    iso2: iso2.toUpperCase(),
    currencyCode,
    currencySymbol,
    currencyName,
  })
);

export const internationalCountries = allCountriesDataset.map(([name, iso2]) => ({
  name,
  iso2: iso2.toUpperCase(),
}));
