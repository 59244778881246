<script lang="ts">
import Vue, { PropType } from "vue";
import { Awaited } from "@/types/utils";
// Components
import BlockButton from "@/components/shared/blocks/button.vue";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";
// import BlockLabel from "@/components/shared/blocks/label.vue";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDropdown from "@/components/shared/blocks/dropdownSearchV3/dropdownSearchV3.vue";
import BlockInfo from "@/components/shared/blocks/info.vue";
import BlockClaimAddress from "@/components/shared/blocks/claimAddress/claimAddress.vue";
import BlockSimpleName from "@/components/shared/blocks/simpleName.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import { DateTime } from "@claimsgate/core/src/forwards/luxon/DateTime";

// Helpers
import { getClaimDataService } from "@/helpers/vue";
import { handleUnknownError } from "../helpers/handleUnknownError";
import { baseProps } from "../helpers/baseProps";
import type { Account } from "../helpers/CheckboardCreditCheck";
import { getUserDetailsFromCheck } from "../helpers/getUserDetailsFromCheck";
import { createNonCraClaim } from "../helpers/createNonCraClaim";
import { Variable } from "@claimsgate/core-types";
import { themesComputed, formComputed, navigationComputed } from "@/state/helpers";
import { DateService } from "@/helpers/ClaimsGate/DateService";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { financeProviderList } from "../helpers/financeProviderList";
import type { Checkboard } from "@claimsgate/core-types";
// import { createClaimStore } from "../helpers/createClaimStore";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { getCheckboardVariableIds } from "../helpers/getCheckboardVariableIds";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { gatewayHelper } from "../helpers/gatewayHelper";
import { getUsersPcpLenders } from "../helpers/getUsersPcpLenders";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { VueConstructor } from "vue";
import { PixelsService } from "@/helpers/ClaimsGate/events/PixelsService";
import { EventsService } from "@/helpers/ClaimsGate/events/EventsService";

const dateService = new DateService();

type Refs = {
  $refs: {
    simpleName: {
      BlockInputs: {
        title: {
          answer: string;
          state: boolean;
        };
        firstName: {
          answer: string;
          state: boolean;
        };
        lastName: {
          answer: string;
          state: boolean;
        };
      };
      computes: () => { title: string; firstName: string; lastName: string };
      validations: () => boolean;
      resetInputs: () => void;
    };
    claimAddress: {
      BlockInputs: {
        addressSelect: {
          answer: string;
          state: boolean;
          invalidFeedback: string;
          options: string[];
        };
        postcode: {
          answer: string;
          state: boolean;
          invalidFeedback: string;
        };
      };
      computes: () => Promise<boolean>;
      validations: () => Promise<boolean>;
    };
  };
};

export default (Vue as VueConstructor<Vue & Refs>).extend({
  name: "Success",
  components: {
    BlockButton,
    CheckboardHeaderContent,
    BlockPadding,
    BlockDatePicker,
    BlockDropdown,
    // BlockLabel,
    BlockInfo,
    BlockClaimAddress,
    BlockSimpleName,
    BlockSingleSelect,
  },
  props: {
    ...baseProps(),
    confirmedLenders: {
      type: Array as PropType<Array<Account>> | null,
      required: false,
    },
    confirmedClaimLender: {
      type: Object as PropType<Account>,
      required: false,
    },
    claimsToCreateEventsFor: {
      type: Array as PropType<Array<string>>,
      required: false,
    },
    stores: {
      type: Array as PropType<Array<Variable>>,
      required: true,
    },
  },
  computed: {
    user(): ReturnType<typeof getUserDetailsFromCheck> {
      return getUserDetailsFromCheck(this.check);
    },
    userFullAddress(): string {
      return [this.user.addressLine1, this.user.addressLine2, this.user.city, this.user.postcode, this.user.country]
        .filter(Boolean)
        .join(", ");
    },
    userFullName(): string {
      return [this.user.title, this.user.firstName, this.user.lastName].filter(Boolean).join(" ");
    },
    ...navigationComputed,
    ...themesComputed,
    ...formComputed,
    lenders(): { start: string; accountNumber?: string; companyName: string; enteredRegistration?: string }[] {
      let lenders: Array<{ start: string; accountNumber?: string; companyName: string; enteredRegistration?: string }> =
        [];

      lenders.push(...(this.check.possibleLenders ?? []));

      if (this.confirmedClaimLender) {
        lenders.push(this.confirmedClaimLender);
      }

      if (this.craCachedLenders.length > 0) {
        lenders.push(...this.craCachedLenders);
      }
      return lenders.filter(
        (lender, index, self) =>
          (!!lender && self.findIndex((l) => l.accountNumber === lender.accountNumber) === index) ||
          !lender.accountNumber
      );
    },
    addedLenders(): {
      start: string;
      accountNumber?: string;
      companyName: string;
      enteredRegistration?: string;
      isDeleting: boolean;
      copiedClaimId?: string;
    }[] {
      let nonCraLenders: Array<{
        start: string;
        accountNumber?: string;
        companyName: string;
        enteredRegistration?: string;
        isDeleting: boolean;
        copiedClaimId?: string;
      }> = [];
      nonCraLenders = [...(this.nonCraLenders ?? [])];

      return nonCraLenders;
    },
    allCachedLenders(): number {
      return this.craCachedLenders.length + this.nonCraCachedLenders.length;
    },
    scenarioCreditCheckWithLenders(): boolean {
      return this.lenders.length > 0 && this.allCachedLenders === 0;
    },
    scenarioNoLenders(): boolean {
      return this.lenders.length === 0 && this.allCachedLenders === 0;
    },
    scenarioCachedReport(): boolean {
      return this.allCachedLenders > 0;
    },
  },
  data() {
    return {
      funnelId: this.check.funnelId,
      isSubmitting: false,
      isSaving: false,
      saveLenderError: null,
      dateService,
      showAgreementForm: false,
      showColllectAgreementDetails: false,
      showCollectDetailsAtTimeOfAgreement: false,
      pixelsService: null as PixelsService | null,
      eventsService: null as EventsService | null,
      infoModalService: new InfoModalService(this.$infoModal),
      BlockInputs: {
        financeLender: UserVariableBlocksService.genBlockDropdownSearchProps({
          label: "Finance Provider",
          placeholder: "Select your finance provider",
          optionsCSV: financeProviderList,
          other: false,
          padding: "20",
        }),
        keeperStartDate: UserVariableBlocksService.genBlockDatePickerProps({
          label: "Date when you acquired the vehicle",
          padding: "0",
          displayDays: false,
          yearsStart: 2006,
          yearsEnd: 2024,
        }),
        enteredRegistration: UserVariableBlocksService.genBlockInputProps({
          label: "Please enter the vehicle registration number",
          padding: "20",
          placeholder: "Enter vehicle registration number",
        }),
      },
      newAgreement: {
        financeLender: "",
        keeperStartDate: "",
        enteredRegistration: "",
        userDetails: {
          title: "",
          firstName: "",
          lastName: "",
        },
        address: "",
      },
      nonCraLenders: [] as {
        start: string;
        accountNumber?: string;
        companyName: string;
        enteredRegistration?: string;
        isDeleting: boolean;
        copiedClaimId?: string;
      }[],
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      variableIds: {} as Awaited<ReturnType<typeof getCheckboardVariableIds>>,
      claimDataService: null,
      craCachedLenders: [] as {
        start: string;
        companyName: string;
        caseType: string;
      }[],
      nonCraCachedLenders: [] as {
        start: string;
        companyName: string;
        enteredRegistration: string;
        caseType: string;
      }[],
      currentNameOnAgreement: null,
      currentAddressOnAgreement: null,
      nameSelectState: null,
      addressSelectState: null,
      userDataService: null as UserDataService | null,
    };
  },
  methods: {
    parseLenderDate(lenderDate: string): Date | null {
      const [year, month, day] = lenderDate.split("-").map((part) => parseInt(part, 10));
      const dateTime = DateTime.fromObject({ year, month, day });
      return dateTime.isValid ? dateTime.toJSDate() : null;
    },

    resetAgreementForm() {
      this.newAgreement = {
        financeLender: "",
        keeperStartDate: null,
        enteredRegistration: "",
        userDetails: {
          title: "",
          firstName: "",
          lastName: "",
        },
        address: "",
      };
      // this resets the validation state of the inputs
      this.BlockInputs.financeLender.state = null;
      this.BlockInputs.keeperStartDate.state = null;
      this.BlockInputs.enteredRegistration.state = null;
      if (this.currentNameOnAgreement === "No" && this.$refs.simpleName) {
        this.$refs.simpleName.resetInputs();
      }
      if (this.currentAddressOnAgreement === "No" && this.$refs.claimAddress) {
        this.resetAddress();
      }
      this.currentNameOnAgreement = null;
      this.currentAddressOnAgreement = null;
      this.nameSelectState = null;
      this.addressSelectState = null;
    },
    async addAgreement() {
      await this.userDataService.refresh();
      this.showAgreementForm = true;
      this.showColllectAgreementDetails = true;
      this.showCollectDetailsAtTimeOfAgreement = false;

      this.saveLenderError = null;
    },
    validateAgreement() {
      let pass = true;
      if (!this.newAgreement.financeLender) {
        pass = false;
        this.BlockInputs.financeLender.invalidFeedback = "Please select your finance provider from the list";
        this.BlockInputs.financeLender.state = false;
      } else {
        this.BlockInputs.financeLender.invalidFeedback = "";
        this.BlockInputs.financeLender.state = true;
      }
      console.log(
        "this.newAgreement.keeperStartDate",
        this.newAgreement.keeperStartDate,
        this.isEligible(this.newAgreement.keeperStartDate)
      );
      if (!this.newAgreement.keeperStartDate) {
        pass = false;
        this.BlockInputs.keeperStartDate.invalidFeedback = "Please enter the date you acquired the vehicle";
        this.BlockInputs.keeperStartDate.state = false;
      } else if (!this.isEligible(this.newAgreement.keeperStartDate)) {
        pass = false;
        this.BlockInputs.keeperStartDate.invalidFeedback = "The vehicle must have been purchased before January 2021.";
        this.BlockInputs.keeperStartDate.state = false;
      } else {
        this.BlockInputs.keeperStartDate.invalidFeedback = "";
        this.BlockInputs.keeperStartDate.state = true;
      }

      if (!this.newAgreement.enteredRegistration) {
        pass = false;
        this.BlockInputs.enteredRegistration.invalidFeedback = "Please enter the vehicle registration number";
        this.BlockInputs.enteredRegistration.state = false;
      } else {
        this.BlockInputs.enteredRegistration.invalidFeedback = "";
        this.BlockInputs.enteredRegistration.state = true;
      }

      return pass;
    },
    validateName() {
      if (this.currentNameOnAgreement === "No") {
        const simpleNameRef = this.$refs.simpleName;

        if (!simpleNameRef) {
          this.isSaving = false;
          return false;
        }

        const isValidName = simpleNameRef.validations();
        if (!isValidName) {
          this.isSaving = false;
          return false;
        }

        this.$refs.simpleName.BlockInputs.title.state = true;
        this.$refs.simpleName.BlockInputs.firstName.state = true;
        this.$refs.simpleName.BlockInputs.lastName.state = true;
      }

      return true;
    },
    validateAddress() {
      const isPostcode = this.$refs.claimAddress.BlockInputs.postcode.answer.length > 0;
      const isSelected = !!this.$refs.claimAddress.BlockInputs.addressSelect.answer;
      const isSearched = this.$refs.claimAddress.BlockInputs.addressSelect.options.length > 0;

      if (!isPostcode && !isSearched) {
        this.$refs.claimAddress.BlockInputs.postcode.state = false;
        this.$refs.claimAddress.BlockInputs.postcode.invalidFeedback = "Please enter the postcode";
        return false;
      }
      if (isPostcode && !isSearched) {
        this.$refs.claimAddress.BlockInputs.postcode.state = false;
        const postcodeLength = this.$refs.claimAddress.BlockInputs.postcode.answer.length;
        const postcodeFeedback =
          postcodeLength > 0 && postcodeLength < 5
            ? "UK postcode should be at least 5 characters long"
            : "Please use search button to find your address";
        this.$refs.claimAddress.BlockInputs.postcode.invalidFeedback = postcodeFeedback;
        return false;
      }
      if (!isSelected) {
        this.$refs.claimAddress.BlockInputs.addressSelect.state = false;
        this.$refs.claimAddress.BlockInputs.addressSelect.invalidFeedback = "Please select an address";
        return false;
      }
      this.$refs.claimAddress.BlockInputs.addressSelect.state = true;
      return true;
    },
    resetValidation() {
      this.saveLenderError = null;
      Object.keys(this.BlockInputs).forEach((key) => {
        this.BlockInputs[key].state = null;
        this.BlockInputs[key].invalidFeedback = "";
      });
      if (this.currentNameOnAgreement) {
        this.nameSelectState = true;
      }
      if (this.currentNameOnAgreement === "No" && this.$refs.simpleName) {
        this.$refs.simpleName.resetInputs();
      }
      if (this.currentAddressOnAgreement) {
        this.addressSelectState = true;
      }
    },
    resetAddress() {
      if (this.currentAddressOnAgreement === "No" && this.$refs.claimAddress) {
        this.$refs.claimAddress.BlockInputs.addressSelect.answer = "";
        this.$refs.claimAddress.BlockInputs.addressSelect.state = null;
        this.$refs.claimAddress.BlockInputs.addressSelect.invalidFeedback = "";
        this.$refs.claimAddress.BlockInputs.addressSelect.options = [];
        this.$refs.claimAddress.BlockInputs.postcode.answer = "";
        this.$refs.claimAddress.BlockInputs.postcode.state = null;
        this.$refs.claimAddress.BlockInputs.postcode.invalidFeedback = "";
      }
    },
    async saveAgreement() {
      this.isSaving = true;

      this.resetValidation();

      // If we are currently showing the Agremeent Form, we need to validate
      // the agreement details
      if (this.showAgreementForm && !this.showCollectDetailsAtTimeOfAgreement) {
        if (!this.validateAgreement()) {
          this.isSaving = false;
          return;
        }

        this.showColllectAgreementDetails = false;
        this.showCollectDetailsAtTimeOfAgreement = true;
        this.isSaving = false;

        return;
      }

      if (!this.currentNameOnAgreement) {
        this.nameSelectState = false;
        this.isSaving = false;
        return;
      }

      if (this.currentNameOnAgreement === "No" && !this.validateName()) {
        this.isSaving = false;
        return;
      }

      if (!this.currentAddressOnAgreement) {
        this.addressSelectState = false;
        this.isSaving = false;
        return;
      }

      if (this.currentAddressOnAgreement === "No" && !this.validateAddress()) {
        this.isSaving = false;
        return;
      }

      try {
        const simpleNameRef = this.$refs.simpleName;
        const addressRef = this.$refs.claimAddress;

        if (this.currentNameOnAgreement === "No") {
          const userDetails = simpleNameRef.computes();
          this.newAgreement.userDetails = userDetails;
          // Store historic names on the user document
          if (this.userDataService) {
            await this.userDataService.refresh();
            let historicNames = this.userDataService.getArtefact("historicNames");

            if (!historicNames) {
              historicNames = [];
              this.userDataService.setArtefact("historicNames", historicNames);
            }
            const fullName = `${userDetails.title} ${userDetails.firstName} ${userDetails.lastName}`;
            if (!historicNames.includes(fullName)) {
              historicNames.push(fullName);
              this.userDataService.setArtefact("historicNames", historicNames);
              await this.userDataService.update();
            }
          }
        } else if (this.currentNameOnAgreement === "Yes") {
          this.newAgreement.userDetails = {
            title: this.user.title,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
          };
        }

        if (this.currentAddressOnAgreement === "No") {
          await addressRef.computes();
          this.newAgreement.address = addressRef.BlockInputs.addressSelect.answer;
        } else if (this.currentAddressOnAgreement === "Yes") {
          this.newAgreement.address = this.userFullAddress;
        }

        const agreement = {
          ...this.newAgreement,
          enteredRegistration: this.formatVehicleRegistration(this.newAgreement.enteredRegistration),
        };

        console.log("agreement", agreement);

        // if all lenders === 0, first manually added lender should be stored on this claim
        if (
          (!this.confirmedClaimLender && this.lenders.length === 0 && this.nonCraLenders.length === 0) ||
          (this.allCachedLenders > 0 && this.addedLenders.length === 0)
        ) {
          // first we check if entered registration is not associated with different claim
          const isNotDupe = await createNonCraClaim({
            claimId: this.check.claimId,
            userId: this.check.userId,
            checkId: this.check.id,
            nonCraLenders: [agreement],
            dupeCheckOnly: true,
          });

          if (!isNotDupe) {
            this.saveLenderError = "The details you provided have already been associated with a different claim.";
            return;
          }
          // if not, we store the new lender on the current claim
          const updateCurrentClaim = {
            [this.variableIds.caseType]: "Manual Entry",
            [this.variableIds.financeLender]: agreement.financeLender,
            [this.variableIds.keeperStartDate]: agreement.keeperStartDate,
            [this.variableIds.enteredRegistration]: agreement.enteredRegistration,
            [this.variableIds.nonCraTitle]: agreement.userDetails.title,
            [this.variableIds.nonCraFirstName]: agreement.userDetails.firstName,
            [this.variableIds.nonCraLastName]: agreement.userDetails.lastName,
            [this.variableIds.nonCraAddress]: agreement.address,
          };

          await this.storeClaimStore(updateCurrentClaim);
          console.log("SAVE AGREEMENT - current claim");
          const parsedNewAgreement = {
            start: agreement.keeperStartDate,
            companyName: agreement.financeLender,
            enteredRegistration: agreement.enteredRegistration,
            isDeleting: false,
            copiedClaimId: this.claimId,
          };
          console.log("1st manually added lender", parsedNewAgreement);
          this.nonCraLenders.push(parsedNewAgreement);
          // if all lenders > 0, manually added lender should be stored on a new copied claim
        } else {
          const result = await createNonCraClaim({
            claimId: this.check.claimId,
            userId: this.check.userId,
            checkId: this.check.id,
            nonCraLenders: [agreement],
            dupeCheckOnly: false,
          });

          if (typeof result === "boolean") {
            return;
          }
          console.log("SAVE AGREEMENT - new claim");
          const returnedLenders = this.parseNonCraLenders(result);

          // since check object returns all nonCraLenders on each call, we need to filter out the ones that are already stored on state
          const lendersToBeStoredOnState = [];

          for (const lender of returnedLenders) {
            if (
              !this.nonCraLenders.some(
                (thisLender) =>
                  thisLender.companyName === lender.companyName &&
                  thisLender.start === lender.start &&
                  thisLender.enteredRegistration === this.formatVehicleRegistration(lender.enteredRegistration)
              )
            ) {
              lendersToBeStoredOnState.push(lender);
            }
          }
          this.nonCraLenders.push(...lendersToBeStoredOnState);
        }
      } catch (error) {
        console.error("saveAgreement error", error);

        this.saveLenderError = "The details you provided have already been associated with a different claim.";

        this.isSaving = false;
      } finally {
        this.showAgreementForm = false;

        this.resetAgreementForm();
        this.resetAddress();
        this.isSaving = false;
      }
    },
    parseNonCraLenders(nonCraLenders: Checkboard.NonCraLender[]) {
      return nonCraLenders.map((nonCraLender) => ({
        start: nonCraLender.keeperStartDate,
        companyName: nonCraLender.financeLender,
        enteredRegistration: this.formatVehicleRegistration(nonCraLender.enteredRegistration),
        isDeleting: false,
        copiedClaimId: nonCraLender.copiedClaimId,
      }));
    },
    async getVariables() {
      return await getCheckboardVariableIds({ funnelService: this.funnelsService, funnelId: this.funnelId });
    },
    async handleSubmit() {
      try {
        // DEPRECATED - current claim is updated from: "checkboardConfirmLender" on the backend before this view is loaded
        //   if (this.check.confirmedClaimLender) {
        //     const claimStore = createClaimStore({
        //       confirmedClaimLender: this.check.confirmedClaimLender,
        //       confirmedLenders: [],
        //       variableIds: this.variableIds,
        //       creditService: this.check.creditService,
        //     });

        //     await this.storeClaimStore(claimStore);
        //   }

        this.$store.dispatch("events/fire", { name: "next" });
      } catch (error) {
        console.error("handleSubmit error", error);
        handleUnknownError(this, error);
      }
    },
    async storeClaimStore(claimStore: { [key: string]: any }) {
      Object.keys(claimStore).forEach((key) => {
        if (!!key && claimStore[key]) {
          this.claimDataService.setArtefact(key, claimStore[key]);
        }
      });

      await this.claimDataService.update();
    },
    parseLenderName(lender: Account) {
      return `${lender.companyName} - (${dateService.toString(lender.start)})`;
    },
    isEligible(start: Account["start"]): boolean {
      if (!this.enforceQualificationPeriod) {
        return true;
      }
      // Split the date string into parts
      const [year, month, day] = start.split("-").map((part) => parseInt(part, 10));

      // Create date using numerical values which handles single and double digits automatically
      const date = DateTime.fromObject({ year, month, day }).toJSDate().setUTCHours(0, 0, 0, 0);

      const eligibleDate = new Date("2021-01-21").setUTCHours(0, 0, 0, 0);

      return date < eligibleDate;
    },
    async handleDelete(claimId: string, enteredRegistration: string) {
      this.saveLenderError = null;
      try {
        await this.infoModalService.fire("delete", {
          title: "Delete Claim",
          text: "Are you sure you want to delete this lender details?",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          showLoader: false,
          onConfirm: async () => {
            try {
              const result = await gatewayHelper("deleteClaimFromDb", {
                enteredRegistration: this.formatVehicleRegistration(enteredRegistration),
                checkId: this.check.id,
                claimId: claimId,
                userId: this.userId,
              });
              console.log("result", result);

              if (!result) {
                this.saveLenderError = "Error deleting claim";
                throw new Error("Delete operation failed");
              }

              // Remove the lender from the nonCraLenders array if deletion was successful
              this.nonCraLenders = this.nonCraLenders.filter(
                (lender) => lender.enteredRegistration !== enteredRegistration
              );
            } catch (error) {
              console.error("Delete confirmation error", error);
              throw error;
            }
          },
        });
      } catch (error) {
        this.saveLenderError = "Error deleting claim";
        console.log("Claim deletion error", error);
        handleUnknownError(this, error);
      } finally {
        this.nonCraLenders.forEach((lender) => {
          lender.isDeleting = false;
        });
      }
    },
    formatVehicleRegistration(vrm: string): string {
      const trimmedVrm = vrm.trim();

      const noSpacesVrm = trimmedVrm.replace(/\s+/g, "");

      const formattedVrm = noSpacesVrm.toUpperCase();

      return formattedVrm;
    },
    handleCloseAgreementForm() {
      this.showAgreementForm = false;
      this.resetAgreementForm();
    },
  },
  async mounted() {
    try {
      window.scrollTo(0, 0);

      await getClaimDataService(this);

      this.userDataService = new UserDataService(this.userId);

      this.variableIds = await this.getVariables();

      // if (!this.headless) {
      //   setTimeout(() => {
      //     this.handleSubmit();
      //   }, 1300);
      // }

      if (this.lenders.length === 0) {
        const cachedLenders = await getUsersPcpLenders(this.userId, this.funnelId);
        this.craCachedLenders = cachedLenders.filter((lender) => lender.caseType === "Credit Checked") as {
          start: string;
          companyName: string;
          caseType: string;
        }[];
        this.nonCraCachedLenders = cachedLenders.filter((lender) => lender.caseType === "Manual Entry") as {
          start: string;
          companyName: string;
          enteredRegistration: string;
          caseType: string;
        }[];
      }

      if (this.lenders.length === 0 && this.nonCraCachedLenders.length === 0) {
        this.addAgreement();
      }

      this.pixelsService = new PixelsService();
      this.eventsService = new EventsService();

      if (this.claimsToCreateEventsFor.length > 0) {
        this.pixelsService.fire({
          name: "NewVerifiedClaimant",
          claimId: this.claimsToCreateEventsFor[0],
          date: new Date(),
          funnelId: this.funnelId,
          pageId: this.claimDataService.getArtefact("pageId"),
          userId: this.userId,
          workspaceId: this.navigationWorkspaceId,
          referrerId: this.claimDataService.getArtefact("referrerId"),
          claimStatus: this.check.claimStatus,
        });

        this.eventsService.fire({
          name: "NewVerifiedClaimant",
          claimId: this.claimsToCreateEventsFor[0],
          date: new Date(),
          funnelId: this.funnelId,
          pageId: this.claimDataService.getArtefact("pageId"),
          userId: this.userId,
          workspaceId: this.navigationWorkspaceId,
          referrerId: this.claimDataService.getArtefact("referrerId"),
          claimStatus: this.check.claimStatus,
        });
      }

      for (const claimId of this.claimsToCreateEventsFor) {
        console.log("Firing event for claimId", claimId);
        this.pixelsService.fire({
          name: "NewFinancialAgreement",
          claimId: claimId,
          date: new Date(),
          funnelId: this.funnelId,
          pageId: this.claimDataService.getArtefact("pageId"),
          userId: this.userId,
          workspaceId: this.navigationWorkspaceId,
          referrerId: this.claimDataService.getArtefact("referrerId"),
          claimStatus: this.check.claimStatus,
        });
      }
    } catch (error) {
      console.error("successCheck mounted error", error);
      handleUnknownError(this, error);
    } finally {
      await this.userDataService.refresh();
    }
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
.gap-16 {
  gap: 16px;
}

.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.uppercase {
  text-transform: uppercase;
}
.reg-input-selected {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.custom-input {
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}

.border-color {
  border-color: #ced4da !important;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.label-color-match {
  color: rgb(23, 60, 80);
}

::v-deep * {
  box-shadow: none !important;
}
</style>
<template>
  <div>
    <!-- TODO: I don't think we need to this anymore?
    <div class="d-flex flex-column gap-20" v-if="!headless">
      <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :hideProgressBar="hideProgressBar"
      :progressValue="'100'"
      :header="`${
        lenders.length === 1 || nonCraCachedLenders.length === 1
        ? `We found ${lenders.length || nonCraCachedLenders.length} car finance agreement`
        : lenders.length > 1 || nonCraCachedLenders.length > 1
        ? `We found ${lenders.length + nonCraCachedLenders.length} car finance agreements`
        : allCachedLenders === 0 && lenders.length === 0 && addedLenders.length > 0
        ? `Your Car Finance Agreements`
        : `We couldn't find any car finance agreements in your name`
      }`"
      :subtitle="`${
        lenders.length > 0
        ? `{{workspaceName}} has identified ${lenders.length} Car Finance Agreement(s), we will start work on each of your agreements immediately to see if you may have been mis-sold.`
        : lenders.length === 0 && addedLenders.length > 0
        ? `we will start work on each of your agreements immediately to see if you may have been mis-sold.`
        : `Is this a mistake? Add information on your car finance agreement(s) and we'll investigate those claims.`
      }`"
      />

      <BlockButton
      :text="'Continue'"
      :variant="'primary'"
      :isProcessing="isSubmitting"
      @clicked="handleSubmit"
      :block="false"
      :submitButtonDefault="false"
      />
    </div>
    -->
    <div class="d-flex flex-column gap-20">
      <CheckboardHeaderContent
        v-if="enableManualAgreements"
        :isBuilder="isBuilder"
        :hideProgressBar="hideProgressBar"
        :progressValue="`${lenders.length > 0 ? `100` : `99`}`"
        :header="`${
          lenders.length + nonCraCachedLenders.length === 1
            ? `We found ${lenders.length || nonCraCachedLenders.length} car finance agreement`
            : lenders.length > 1 || nonCraCachedLenders.length > 1
            ? `We found ${lenders.length + nonCraCachedLenders.length} car finance agreements`
            : allCachedLenders === 0 && lenders.length === 0 && addedLenders.length > 0
            ? `Your Car Finance Agreements`
            : `We couldn't find any car finance agreements in your name`
        }`"
        :subtitle="`${
          lenders.length > 0 || nonCraCachedLenders.length > 0
            ? `{{workspaceName}} has identified ${
                lenders.length + nonCraCachedLenders.length
              } Car Finance Agreement(s), we will start investigating each of these agreements on your behalf to determine if you may have been mis-sold.`
            : lenders.length === 0 && addedLenders.length > 0
            ? `We will start investigating each of these agreements on your behalf to determine if you may have been mis-sold.`
            : `Is this a mistake? Add information on your car finance agreement(s) and we'll investigate those claims.`
        }`"
      />
      <CheckboardHeaderContent
        v-else
        :isBuilder="isBuilder"
        :hideProgressBar="hideProgressBar"
        :progressValue="`100`"
        :header="`${
          lenders.length === 1
            ? `We found ${lenders.length} car finance agreement`
            : lenders.length > 1
            ? `We found ${lenders.length} car finance agreements`
            : scenarioNoLenders
            ? `We couldn't find any car finance agreements in your name`
            : `Your Car Finance Agreements`
        }`"
        :subtitle="`${
          lenders.length > 0
            ? `{{workspaceName}} has identified ${lenders.length} Car Finance Agreement(s), we will start investigating each of these agreements on your behalf to determine if you may have been mis-sold.`
            : scenarioNoLenders
            ? `Credit check has been completed. We were able to validate your identity, but we were unable to find any car finance agreements in your records.`
            : `We will start investigating each of these agreements on your behalf to determine if you may have been mis-sold.`
        }`"
      />
      <div v-if="lenders.length > 0" class="d-flex flex-column gap-16">
        <h5 class="text-grey-900 font-weight-semibold my-2">Your Results</h5>
        <template v-for="(lender, index) in lenders">
          <div :key="index + 'lender'">
            <div class="d-flex justify-content-between align-items-center"></div>
            <b-card-body
              :class="['no-body shadow-sm px-4 py-3', isEligible(lender.start) ? 'bg-success-50' : 'bg-danger-50']"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <p
                    :class="[
                      'font-weight-semibold text-md mb-0',
                      isEligible(lender.start) ? 'text-success-700' : 'text-danger-700',
                    ]"
                  >
                    <b>{{ lender.companyName }}</b>
                  </p>
                  <p
                    :class="[
                      'text-sm font-weight-semibold mb-0',
                      isEligible(lender.start) ? 'text-success-600' : 'text-danger-600',
                    ]"
                  >
                    {{ dateService.toString(parseLenderDate(lender.start)) }}

                    <span v-if="lender.enteredRegistration">
                      , Vehicle Registration: {{ formatVehicleRegistration(lender.enteredRegistration) }}</span
                    >
                  </p>
                </div>
                <div class="ml-auto d-flex align-items-center">
                  <b-icon
                    v-if="isEligible(lender.start)"
                    icon="check-circle-fill"
                    variant="success"
                    class="text-success-600"
                    font-scale="1.5"
                  >
                  </b-icon>
                  <b-icon v-else icon="x-circle-fill" variant="danger" class="text-danger-600" font-scale="1.5">
                  </b-icon>
                </div>
              </div>
            </b-card-body>
            <BlockPadding padding="10" />
            <div class="flex-grow-1">
              <p class="mb-0 text-sm text-danger" v-if="!isEligible(lender.start)">
                The vehicle must have been purchased before January 2021.
              </p>
            </div>
          </div>
        </template>
      </div>
      <template v-if="enableManualAgreements">
        <!-- Manually added lenders -->
        <div class="d-flex flex-column gap-16" v-if="addedLenders.length > 0 || nonCraCachedLenders.length > 0">
          <h5 class="text-grey-900 font-weight-semibold my-2">Additional Agreements</h5>

          <template v-for="(cachedLender, index) in nonCraCachedLenders">
            <div :key="index + 'cachedLender'">
              <b-card-body
                :class="[
                  'no-body shadow-sm px-4 py-3',
                  isEligible(cachedLender.start) ? 'bg-success-50' : 'bg-danger-50',
                ]"
              >
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column">
                    <p
                      :class="[
                        'font-weight-semibold text-md mb-0',
                        isEligible(cachedLender.start) ? 'text-success-700' : 'test-danger-700',
                      ]"
                    >
                      <b>{{ cachedLender.companyName }}</b>
                    </p>
                    <p
                      :class="[
                        'text-sm font-weight-semibold mb-0',
                        isEligible(cachedLender.start) ? 'text-success-600' : 'text-danger-600',
                      ]"
                    >
                      {{ dateService.toString(parseLenderDate(cachedLender.start)) }},
                      <span> Vehicle Registration: {{ cachedLender.enteredRegistration }}</span>
                    </p>
                  </div>
                  <div class="ml-auto d-flex align-items-center">
                    <b-icon
                      v-if="isEligible(cachedLender.start)"
                      icon="check-circle-fill"
                      variant="success"
                      class="text-success-600"
                      font-scale="1.5"
                    >
                    </b-icon>
                    <b-icon v-else icon="x-circle-fill" variant="danger" class="text-danger-600" font-scale="1.5">
                    </b-icon>
                  </div>
                </div>
              </b-card-body>
              <BlockPadding padding="10" />
              <div class="flex-grow-1">
                <p class="mb-0 text-sm text-danger" v-if="!isEligible(cachedLender.start)">
                  The vehicle must have been purchased before January 2021.
                </p>
              </div>
            </div>
          </template>
          <template v-for="(addedLender, index) in addedLenders">
            <div :key="index + 'addedLender'">
              <b-card-body
                :class="[
                  'no-body shadow-sm px-4 py-3',
                  isEligible(addedLender.start) ? 'bg-success-50' : 'bg-danger-50',
                ]"
              >
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column">
                    <p
                      :class="[
                        'font-weight-semibold text-md mb-0',
                        isEligible(addedLender.start) ? 'text-success-700' : 'test-danger-700',
                      ]"
                    >
                      <b>{{ addedLender.companyName }}</b>
                    </p>
                    <p
                      :class="[
                        'text-sm font-weight-semibold mb-0',
                        isEligible(addedLender.start) ? 'text-success-600' : 'text-danger-600',
                      ]"
                    >
                      {{ dateService.toString(parseLenderDate(addedLender.start)) }},
                      <span> Vehicle Registration: {{ addedLender.enteredRegistration }}</span>
                    </p>
                  </div>
                  <div class="ml-auto d-flex align-items-center">
                    <b-icon
                      v-if="isEligible(addedLender.start)"
                      icon="check-circle-fill"
                      variant="success"
                      class="text-success-600"
                      font-scale="1.5"
                    >
                    </b-icon>
                    <b-icon v-else icon="x-circle-fill" variant="danger" class="text-danger-600" font-scale="1.5">
                    </b-icon>
                    <b-button
                      v-if="
                        ((scenarioNoLenders || scenarioCachedReport) && index > 0) || scenarioCreditCheckWithLenders
                      "
                      size="lg"
                      class="mb-0 p-0 ml-2 text-md text-red-600"
                      variant="primary"
                      style="background: transparent; border: none; line-height: 1"
                      @click="
                        if (addedLender.copiedClaimId === claimId) {
                          saveLenderError = 'You can\'t delete this original claim';
                          return;
                        }
                        addedLender.isDeleting = true;
                        handleDelete(addedLender.copiedClaimId, addedLender.enteredRegistration);
                      "
                    >
                      <b-icon v-if="!addedLender.isDeleting" icon="x" scale="1.5" class="icon"></b-icon>
                      <b-spinner v-else small></b-spinner>
                    </b-button>
                  </div>
                </div>
              </b-card-body>
              <BlockPadding padding="10" />
              <div class="flex-grow-1">
                <p class="mb-0 text-sm text-danger" v-if="!isEligible(addedLender.start)">
                  The vehicle must have been purchased before January 2021.
                </p>
              </div>
            </div>
          </template>
        </div>
        <div v-if="showAgreementForm" class="d-flex flex-column">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="text-grey-900 font-weight-semibold mt-4">Add Agreement</h5>
            <b-button
              size="lg"
              class="mb-0 text-md text-grey-600 p-0 hover-red-600"
              variant="primary"
              style="background: transparent; border: none"
              @click="handleCloseAgreementForm"
              ><b-icon icon="x" scale="1.5" class="icon"></b-icon>
            </b-button>
          </div>

          <div v-if="showColllectAgreementDetails">
            <BlockPadding padding="10" />

            <BlockInfo :info="'Vehicle Registration Number'" class="mb-1 h5 text-black label-color-match" />
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="justify-content-center reg-input-selected custom-input">
                  <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                class="border reg-input-selected custom-input uppercase"
                size="lg"
                maxLength="15"
                v-model="newAgreement.enteredRegistration"
                :state="BlockInputs.enteredRegistration.state"
                :invalidFeedback="BlockInputs.enteredRegistration.invalidFeedback"
                placeholder="Example: AB12 CDE"
                @update="BlockInputs.enteredRegistration.state = null"
              ></b-form-input>
            </b-input-group>
            <template v-if="!BlockInputs.enteredRegistration.state">
              <b-form-invalid-feedback :state="BlockInputs.enteredRegistration.state" id="input-live-feedback">
                {{ BlockInputs.enteredRegistration.invalidFeedback }}
              </b-form-invalid-feedback>
            </template>
            <BlockDropdown v-bind="BlockInputs.financeLender" :answer.sync="newAgreement.financeLender" />

            <BlockDatePicker v-bind="BlockInputs.keeperStartDate" :answer.sync="newAgreement.keeperStartDate" />

            <BlockPadding padding="20" />
          </div>

          <div v-if="showCollectDetailsAtTimeOfAgreement">
            <h5 class="text-grey-900 font-weight-semibold mt-4">Agreement Holder Details</h5>
            <BlockSingleSelect
              :state="nameSelectState"
              :options="['Yes', 'No']"
              :label="`Was your name ${userFullName} at the time of the agreement?`"
              :answer.sync="currentNameOnAgreement"
            />

            <div v-if="currentNameOnAgreement === 'No'">
              <h5 class="text-grey-900 mt-4">Please tell us your details at the time of the agreement</h5>
              <BlockSimpleName ref="simpleName" :middleName="false" />
            </div>
            <div class="d-flex flex-column mt-4">
              <BlockSingleSelect
                :state="addressSelectState"
                :options="['Yes', 'No']"
                :label="`Did you live at ${userFullAddress} at the time of the agreement?`"
                :answer.sync="currentAddressOnAgreement"
              />
              <div v-if="currentAddressOnAgreement === 'No'">
                <h5 class="text-grey-900 mt-4">Please tell us your address at the time of the agreement</h5>
                <BlockClaimAddress ref="claimAddress" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!showAgreementForm" class="text-sm text-grey-600">
          Don't see all of your agreements? Add more to increase your potential compensation; each will be assessed
          separately.
        </div>
        <div v-else class="text-sm text-grey-600">
          Have multiple agreements? Don't worry, you'll be able to add more after this step.
        </div>
      </template>
      <div class="d-grid gap-2 mx-auto">
        <template v-if="enableManualAgreements">
          <p v-if="saveLenderError" class="mb-2 text-md text-center text-danger font-weight-bold">
            {{ saveLenderError }}
          </p>
          <BlockButton
            v-if="showAgreementForm"
            @clicked="saveAgreement"
            :text="'Continue'"
            :variant="'primary'"
            :block="true"
            :submitButtonDefault="false"
            :isProcessing="isSaving"
          />

          <BlockButton
            v-if="!showAgreementForm"
            @clicked="addAgreement"
            :text="'Add another agreement'"
            :variant="'outline-light text-dark bg-white border border-1 border-grey-300'"
            :submitButtonDefault="false"
            :block="true"
          >
            <b-icon icon="plus" scale="1.5" class="icon"></b-icon>
          </BlockButton>
        </template>

        <BlockButton
          v-if="!enableManualAgreements || !showAgreementForm"
          @clicked="handleSubmit"
          :text="'Continue'"
          :variant="'primary'"
          :isProcessing="loaders.next"
          :block="true"
          padding="10"
          :submitButtonDefault="false"
        />
      </div>
    </div>
  </div>
</template>
