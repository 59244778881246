import { Props } from "@/types";

export function getProps() {
  return {
    padding: {
      type: String,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,
    isBuilder: {
      type: Boolean,
      default: false,
      description: "Whether the block is being used in the builder",
    } as Props.CGPropExtras<boolean>,
    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: string; type: string }> => [
        { name: "craProductType", type: "string" },
        { name: "craFinanceLender", type: "string" },
        { name: "craCompanyType", type: "string" },
        { name: "craAgreementNumberOriginal", type: "string" },
        { name: "craAgreementNumber", type: "string" },
        { name: "craCreditStartDate", type: "date" },
        { name: "craCurrentBalance", type: "string" },
        { name: "craCreditEndDate", type: "date" },
        { name: "craSettlementDate", type: "date" },
        { name: "craAccountStatus", type: "string" },
        { name: "craLengthOfContract", type: "string" },
        { name: "craEstimatedDebt", type: "number" },
        { name: "financeLender", type: "string" },
        { name: "keeperStartDate", type: "date" },
        { name: "enteredRegistration", type: "number" },
        { name: "parentClaimId", type: "string" },
        { name: "caseType", type: "string" },
        { name: "craAgreementAddress", type: "T" },
        { name: "craCaseType", type: "string" },
        { name: "craCheckAttempted", type: "string" },
        { name: "craCheckDate", type: "date" },
        { name: "craCheckChargeable", type: "string" },
        { name: "craCheckIdentityOutcome", type: "string" },
        { name: "craCheckLendersReturned", type: "number" },
        { name: "craTitle", type: "string" },
        { name: "craFirstName", type: "string" },
        { name: "craLastName", type: "string" },
        { name: "craDateOfBirth", type: "string" },
        { name: "nonCraTitle", type: "string" },
        { name: "nonCraFirstName", type: "string" },
        { name: "nonCraLastName", type: "string" },
        { name: "nonCraAddress", type: "string" },
        { name: "craAMLCheck", type: "string" },
        { name: "craAMLDecisionReason", type: "string" },
        { name: "craClassificationStatus", type: "string" },
        { name: "craClassificationReason", type: "string" },
        { name: "craClassificationDate", type: "date" },
        { name: "valifiReportId", type: "number" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: string; type: string }>>,
    useValidateRunCheck: {
      type: Boolean,
      default: false,
      description: "Whether to use validateRunCheck to run the check",
    } as Props.CGPropExtras<boolean>,
    useValifiRunCheck: {
      type: Boolean,
      default: false,
      description: "Whether to use valifiRunCheck to run the check",
    } as Props.CGPropExtras<boolean>,
    enableManualAgreements: {
      type: Boolean,
      default: false,
      description: "Whether to allow users to manually add agreements",
    } as Props.CGPropExtras<boolean>,
    headless: {
      type: Boolean,
      default: false,
      description: "Whether to run the check in headless mode",
    } as Props.CGPropExtras<boolean>,
    allowCheckboardFallback: {
      type: Boolean,
      default: false,
      description: "Whether to allow the checkboard fallback",
    } as Props.CGPropExtras<boolean>,
    skipApproveCheck: {
      type: Boolean,
      default: false,
      description: "Whether to skip the approve check view",
    } as Props.CGPropExtras<boolean>,
    hideProgressBar: {
      type: Boolean,
      default: false,
      description: "Whether to hide the progress bar",
    } as Props.CGPropExtras<boolean>,
    extraClaimsPageId: {
      type: String,
      default: null,
      description: "Page Id that the extra claims should be moved to when created",
    } as Props.CGPropExtras<string>,
    enforceQualificationPeriod: {
      type: Boolean,
      default: true,
      description: "Reject PCP agreements with start date after 21st January 2021",
    } as Props.CGPropExtras<boolean>,
  };
}
