import { Account } from "./CheckboardCreditCheck";

export function createClaimStore({
  confirmedClaimLender,
  confirmedLenders,
  variableIds,
  creditService,
}: {
  confirmedClaimLender: Account;
  confirmedLenders: Account[];
  variableIds: { [key: string]: string };
  creditService: "validate" | "valifi" | "checkboard";
}) {
  const claimStore = {};
  console.log("createClaimStore", confirmedLenders, confirmedClaimLender);

  if (confirmedClaimLender) {
    // claimStore[variableIds.craFinanceAgreement] = confirmedClaimLender;

    const checkBoardPrimitiveAccountKeys: Array<[string, string | string[]]> = [
      ["craProductType", "type"],
      ["craFinanceLender", "companyName"],
      ["craCompanyType", "companyClass"],
      ["craAgreementNumber", "accountNumber"],
      ["craCreditStartDate", "start"],
      ["craCurrentBalance", ["balance", "current_balance"]],
      ["craCreditEndDate", "end"],
    ];

    const validatePrimitiveAccountKeys: Array<[string, string | string[]]> = [
      ["craProductType", "type"],
      ["craFinanceLender", "companyName"],
      ["craCompanyType", "companyClass"],
      ["craAgreementNumberOriginal", "preProcessedAccountNumber"],
      ["craAgreementNumber", "accountNumber"],
      ["craMonthlyPayment", "monthlyPayment"],
      ["craBalanceHistory", "balanceHistory"],
      ["craCreditStartDate", "start"],
      ["craCurrentBalance", ["balance", "current_balance"]],
      ["craCreditEndDate", "end"],
      ["craSettlementDate", "settlementDate"],
      ["craAccountStatus", "accountStatus"],
      ["craLengthOfContract", "lengthOfContract"],
      ["craEstimatedDebt", "estimatedDebt"],
      ["craAgreementAddress", "agreementAddress"],
      ["caseType", "caseType"],
      ["craCaseType", "craCaseType"],
      ["craCheckAttempted", "craCheckAttempted"],
      ["craCheckDate", "craCheckDate"],
      ["craCheckChargeable", "craCheckChargeable"],
      ["craCheckIdentityOutcome", "craCheckIdentityOutcome"],
      ["craCheckLendersReturned", "craCheckLendersReturned"],
      ["craTitle", "craTitle"],
      ["craFirstName", "craFirstName"],
      ["craLastName", "craLastName"],
      ["craDateOfBirth", "craDateOfBirth"],
      ["nonCraTitle", "nonCraTitle"],
      ["nonCraFirstName", "nonCraFirstName"],
      ["nonCraLastName", "nonCraLastName"],
      ["nonCraAddress", "nonCraAddress"],
      ["craAMLCheck", "craAMLCheck"],
      ["craAMLDecisionReason", "craAMLDecisionReason"],
      ["craClassificationStatus", "craClassificationStatus"],
      ["craClassificationReason", "craClassificationReason"],
      ["craClassificationDate", "craClassificationDate"],
      ["valifiReportId", "valifiReportId"],
    ];

    const primitiveAccountKeys =
      creditService === "checkboard" ? checkBoardPrimitiveAccountKeys : validatePrimitiveAccountKeys;

    primitiveAccountKeys.forEach(([cgClaimKey, cbAccountKey]) => {
      if (Array.isArray(cbAccountKey)) {
        if (confirmedClaimLender[cbAccountKey[0]]?.[cbAccountKey[1]])
          claimStore[variableIds[cgClaimKey]] = confirmedClaimLender[cbAccountKey[0]][cbAccountKey[1]];
      } else if (confirmedClaimLender[cbAccountKey]) {
        claimStore[variableIds[cgClaimKey]] = confirmedClaimLender[cbAccountKey];
      }
    });
  }

  console.log("claimStore", claimStore);
  return claimStore;
}
