<script lang="ts">
import Vue from "vue";
//Helper
import { baseProps } from "../helpers/baseProps";
import { handleUnknownError } from "../helpers/handleUnknownError";
//components
import BlockButton from "@/components/shared/blocks/button.vue";
import { formComputed, navigationComputed } from "@/state/helpers";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";

export default Vue.extend({
  name: "Fail",
  components: { BlockButton, CheckboardHeaderContent },
  props: {
    ...baseProps(),
    failReason: {
      type: String,
      default: null,
    },
    referUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...formComputed,
    ...navigationComputed,
  },
  data() {
    return {
      isSubmitting: false,
      pageId: this.$route.params.pageId,
      funnelId: this.$route.params.funnelId,
      errorMessages: {
        id: "User identity couldn't be verified. You might have entered incorrect details or your credit file is too thin to verify your identity. Please try again.",
        noAddress:
          "We couldn't find any addresses for the provided postcode. Please try again with a different postcode.",
        kbaSubmit: "Your answers were incorrect. Please try again later.",
        unknown: "An unknown error occurred. Please try again later.",
      },
    };
  },
  methods: {
    handleSkip() {
      this.$store.dispatch("events/fire", { name: "next" });
    },
    async handleTryAgain() {
      try {
        this.isSubmitting = true;

        if (this.referUrl) {
          window.location.href = this.referUrl;
        } else {
          const funnelId = this.$route.params.funnelId;
          const wrefParam = this.$route.fullPath.split("?").find((param) => param.startsWith("wref"));
          const param = wrefParam ? "?" + wrefParam : "";
          console.log("domain url", window.location.origin);
          const url = `${window.location.origin}/form/${funnelId}${param}`;

          window.location.href = url;
        }

        // viewSubmit(this, "fail", result);

        this.isSubmitting = false;
      } catch (error) {
        console.error(`Error submitting lenders: ${error}`);

        handleUnknownError(this, this.check);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
</style>
<template>
  <div class="d-flex flex-column gap-20">
    <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :hideProgressBar="hideProgressBar"
      :progressValue="'0'"
      :header="'Unable to complete credit check'"
      :subtitle="`Unfortunately we have been unable to complete your credit check.<br><br><span class='text-danger-700'>${
        failReason ? `Error:<br><br> ${errorMessages[failReason]}` : errorMessages.unknown
      }<br><br></span>`"
      :text="`Alternatively you may skip this step and continue with your claim, by clicking the skip button below.`"
    />

    <BlockButton
      :text="'Try Again'"
      :variant="'primary'"
      :isProcessing="isSubmitting"
      @clicked="handleTryAgain"
      :block="false"
      :submitButtonDefault="false"
    />
    <BlockButton
      :text="'Skip'"
      :variant="'secondary'"
      :isProcessing="loaders.next"
      @clicked="handleSkip"
      :block="false"
      :submitButtonDefault="false"
    />
  </div>
</template>
